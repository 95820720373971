import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useProcedureLookupQuery } from "../../../../../../services/orgService/org-api";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useState } from "react";
import { EstimatedProcedure } from "../../../../../../services/verifyService/model/verification/EstimatedProcedure";
import {
  addProcedure,
  sendToTopProcedure,
} from "../../../../../../services/verifyService/model/verification/VerificationSlice";
import { ProcedureLookupRequest } from "../../../../../../services/verifyService/commands";
import { toggleDrgGroupMatching } from "../../../../../../app/slices/VerificationModuleSlice";
import { DrgRemovalMessage } from "./DrgRemovalMessage";


function DrgGroupMatching() {
  const isOpen = useAppSelector(state => state.verificationModule.DrgGroupMatching);
  var dispatch = useAppDispatch();
  const add = (code: string, description: string) => {
    var estimatedProc = new EstimatedProcedure(code, description);
    dispatch(addProcedure(estimatedProc));
    dispatch(sendToTopProcedure(estimatedProc.priority));
  };
  const verification = useAppSelector(
    (state) => state.verification.verification
  );
  const [matchedDrg, setMatchedDrg] = React.useState(new EstimatedProcedure("", ""));
  const { data } = useProcedureLookupQuery(
    verification.drgCombo! ? new ProcedureLookupRequest(verification.center, verification.drgCombo.DRG) : skipToken
  );
  const [notify, setNotify] = useState(false);

  const handleSave = () => {
    dispatch(toggleDrgGroupMatching());
    setNotify(false);
    let existingDrg = verification.procedures.find(p => p.code === verification.drgCombo.DRG);
    if (matchedDrg.code !== existingDrg?.code) {
      add(matchedDrg.code!, matchedDrg.description!);
    }
  };

  React.useEffect(() => {
    if (data && data.length > 0) {
      const matched = data.find((item) => item.code === verification.drgCombo.DRG);
      if (matched) {
        setMatchedDrg(new EstimatedProcedure(matched.code!, matched.description!));
        setNotify(true);
      }    
    }
  }, [data, verification.drgCombo]);

  return (
    <React.Fragment>
      {notify && (        
        <Dialog open={isOpen}>
      <DialogTitle>DRG Code Matched</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack direction="column" spacing={1}>
            <strong>DRG:</strong> {matchedDrg.code}
            <strong>Description:</strong> {matchedDrg.description}
            <strong>CPT Combo:</strong> {verification!.drgCombo.CPT.join(", ")}
            <DrgRemovalMessage />
            </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()} color="primary">
        OK
        </Button>
      </DialogActions>
      </Dialog>
      )}
    </React.Fragment>
  );
}

export { DrgGroupMatching };
